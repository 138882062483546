<template>

        <div class="col-md-3 pb-2">
            <div class="card mb-2">
                <h5 class="card-header">{{ parcoursID }}</h5>
                <div class="card-body text-center pl-1 pr-1" v-if="isBlocked === true">
                    <h1 class="pb-2" v-if="lastAttendees == 0">Einweisung läuft</h1>
                    <h1 class="pb-2" v-if="lastAttendees > 0">Einweisung läuft ({{ lastAttendees }} <i class="bi bi-person"></i>)</h1>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" v-bind:style="{width: formattedBlockTimePercent + '%'}"></div>
                    </div>
                    <h5 class="pt-3" v-if="formattedBlockTime === 1">seit ca. {{ formattedBlockTime }} Minute</h5>
                    <h5 class="pt-3" v-if="formattedBlockTime !== 1">seit ca. {{ formattedBlockTime }} Minuten</h5>
                    <h5>Trainer: {{ lastTrainer }}</h5>
                    <button class="btn btn-lg btn-warning btn-block mt-4" @click.prevent="stopBlocker" data-toggle="tooltip" data-placement="top" title="Parcours freigeben"><i class="bi bi-stop-fill"></i> Parcours freigeben</button>
                    <p class="small pt-1"><i>Automatische Freigabe nach {{ blockDuration }} Minuten</i></p>
                </div>
                <div class="card-body text-center pl-1 pr-1" v-if="isBlocked === false">
                    <div class="card-body text-center pt-1">
                        <h1 v-bind:class="{ warningformat: count >= attendeeWarning1 && count < attendeeWarning2, criticalformat: count != 0 && count >= attendeeWarning2 }"><i class="bi bi-people-fill" data-toggle="tooltip" data-placement="bottom" title="Wartende Gäste"></i> {{ count }}</h1>
                    </div>
                    <div class="card-body text-center pt-1 pb-3">
                        <h1 v-bind:class="{ warningformat: elapsedTime >= (waitWarning1 * 60 * 1000) && elapsedTime < (waitWarning2 * 60 * 1000), criticalformat: elapsedTime != 0 && elapsedTime >= (waitWarning2 * 60 * 1000) }"><i class="bi bi-stopwatch" data-toggle="tooltip" data-placement="bottom" title="Wartezeit"></i> {{ formattedElapsedTime }}</h1>
                    </div>
                    <button class="btn btn-lg btn-primary btn-block" @click.prevent="incrementCounter(1)" data-toggle="tooltip" data-placement="top" title="Person zur Einweisung hinzufügen">+ <i class="bi bi-person-fill"></i></button>
                    <div class="row pt-2">
                        <div class="col-6 pr-1">
                            <button class="btn btn-lg btn-primary btn-block" @click.prevent="incrementCounter(5)" data-toggle="tooltip" data-placement="top" title="5 Personen zur Einweisung hinzufügen">+5 <i class="bi bi-person-fill"></i></button>
                        </div>
                        <!-- <div class="col-4 pl-1 pr-1">
                            <button class="btn btn-lg btn-primary btn-block" @click.prevent="incrementCounter(10)" data-toggle="tooltip" data-placement="top" title="10 Personen zur Einweisung hinzufügen">+10 <i class="bi bi-person-fill"></i></button>
                        </div> -->
                        <div class="col-6 pl-1 pb-3">
                            <button class="btn btn-lg btn-danger btn-block" @click.prevent="decrementCounter" data-toggle="tooltip" data-placement="top" title="Person von der Einweisung entfernen">-<i class="bi bi-person-fill"></i></button>
                        </div>
                    </div>
                    <div class="row pt-2 pb-2">
                        <div class="col-2 pr-0 text-center"><h3 class="text-center"><i class="bi bi-person-bounding-box" data-toggle="tooltip" data-placement="bottom" title="vorgemerkter Trainer"></i></h3></div>
                        <div class="col pl-0">
                            <select class="custom-select mb-3 form-control" v-model="preselectedTrainer" @change="selectTrainer($event)">
                                <option></option>
                                <option v-for="trainer in trainers" :key="trainer.trainerName" :value="trainer.trainerName" >{{ trainer.trainerName }}</option>
                            </select>
                        </div>
                    </div>
                    <button class="btn btn-lg btn-warning btn-block" @click.prevent="startSB" data-toggle="tooltip" data-placement="top" title="Einweisung starten"><i class="bi bi-play-fill"></i> Einweisung starten</button>
                </div>
            </div>
        </div>

</template>

<style scoped>
.warningformat {
    color: orange;
}
.criticalformat {
    color: #d32324;
}
</style>

<script>
import router from '../router'
import { db } from '../firebaseDb'
import firebase from 'firebase'
import '../assets/bootstrap-icons-1.2.2/font/bootstrap-icons.css'
// import { moment } from 'moment'
import store from "../store"

export default {
    name: 'Timer',
    props: {
        parcourskey: {
            type: String
        }
    },
  data () {
    return {
        count: 0,
        parcoursID: null,
        timeStart: null,
        isRunning: false,
        isWaiting: false,
        timer: undefined,
        elapsedTime: 0,
        docID: null,
        trainers: [],
        preselectedTrainer: null,
        isBlocked: false,
        blockedUntil: null,
        remainingBlockTime: 0,
        lastTrainer: null,
        isBlockerActive: false,
        lastAttendees: 0,
        attendeeWarning1: null,
        attendeeWarning2: null,
        waitWarning1: null,
        waitWarning2: null,
        blockDuration: null,
        briefingID: null,
        timeOffset: null
    }
  },
  created () {
    if (localStorage.timeOffset) {
        this.timeOffset = localStorage.timeOffset
    } else {
        this.timeOffset = 0
    }
      const parcours = db.collection('parcours')
      parcours.doc(this.parcourskey).onSnapshot((doc) => {
          this.docID = doc.id
          this.count = doc.data().attendees
          this.parcoursID = doc.data().parcoursID
          this.startWait = doc.data().startWait
          this.isWaiting = doc.data().isWaiting
          this.preselectedTrainer = doc.data().trainer
          this.isBlocked = doc.data().isBlocked
          this.blockedUntil = doc.data().blockedUntil
          this.lastTrainer = doc.data().lastTrainer
          this.attendeeWarning1 = doc.data().attendeeWarning1
          this.attendeeWarning2 = doc.data().attendeeWarning2
          this.waitWarning1 = doc.data().waitWarning1
          this.waitWarning2 = doc.data().waitWarning2
          this.blockDuration = doc.data().blockDuration
          this.briefingID = doc.data().briefingID
          this.lastAttendees = doc.data().lastAttendees || 0
    })
      // get trainer
      const trainerCollection = db.collection('trainer')
      trainerCollection.where('uid', '==', store.getters.user.data.uid).orderBy('trainerName').onSnapshot((snapshotChange) => {
        this.trainers = []
        snapshotChange.forEach((doc) => {
            this.trainers.push({
                key: doc.id,
                trainerName: doc.data().trainerName
                })
        })
        })
  },
  updated () {
    if (this.isWaiting === true && this.count > 0 && this.isRunning === false) {
        var nowTimeStamp = Date.now() + parseInt(this.timeOffset)
        this.elapsedTime = nowTimeStamp - this.startWait
        this.startTimer()
    }
    if (this.count === 0 && this.isRunning === true) {
        this.stopTimer()
    }
    if (this.isBlocked === true && this.isBlockerActive === false) {
        this.startBlocker()
    }
    // Reset count to 0 if value is < 0 due to simultaneously decrease from multiple devices
    if (this.count < 0) {
        this.count = 0
    }
  },
  methods: {
        startTimer () {
            this.isRunning = true
            console.log("Start or resume Timer")
            this.timer = setInterval(() => {
                var nowTimeStamp = Date.now() + parseInt(this.timeOffset)
                this.elapsedTime = nowTimeStamp - this.startWait
                if (this.isWaiting === false) {
                    this.stopTimer()
                }
            }, 1000)
        },
        resumeTimer () {
            console.log("resume elapsed: " + this.elapsedTime)
            this.timer = setInterval(() => {
                this.elapsedTime += 1000
                if (this.isWaiting === false) {
                    console.log("resume stop")
                    this.stopTimer()
                }
            }, 1000)
            this.isRunning = true
        },
        stopTimer () {
            console.log("Stop Timer")
            this.isRunning = false
            this.elapsedTime = 0
            clearInterval(this.timer)
        },
        resetTimer () {
            this.elapsedTime = 0
        },
        startBlocker () {
            console.log("Start or resume Blocker")
            this.isBlockerActive = true
            this.blockerTimer = setInterval(() => {
                var nowTimeStamp = Date.now() + parseInt(this.timeOffset)
                this.remainingBlockTime = this.blockedUntil - nowTimeStamp
                if (this.isBlocked === false || this.remainingBlockTime <= 0) {
                    this.stopBlocker()
                }
            }, 5000)
        },
        stopBlocker () {
            console.log("Stop Blocker")
            this.isBlockerActive = false
            this.remainingBlockTime = 0
            this.isBlocked = false
            clearInterval(this.blockerTimer)
            // update database
            const parcoursRef = db.collection('parcours').doc(this.docID)
            parcoursRef.update({ isBlocked: false })
        },
        resetBlocker () {
            this.remainingBlockTime = 0
        },
        incrementCounter: function (step = 1) {
            if (this.count < 50) {
                if (this.count < 1) {
                    this.count += step
                    // console.log('increment')
                    this.timeStart = Date.now() + parseInt(this.timeOffset)
                    // update database
                    const parcoursRef = db.collection('parcours').doc(this.docID)
                    const increment = firebase.firestore.FieldValue.increment(step)
                    this.isWaiting = true
                    this.briefingID = this.generateID()
                    parcoursRef.update({ attendees: increment, startWait: this.timeStart, isWaiting: true, briefingID: this.briefingID })
                    this.startTimer()
                    console.log('done')
                } else {
                    this.count += step
                    console.log("Increment counter gt 0 " + this.count)
                    // update database
                    const parcoursRef = db.collection('parcours').doc(this.docID)
                    const increment = firebase.firestore.FieldValue.increment(step)
                    parcoursRef.update({ attendees: increment })
                }
            }
        },
        decrementCounter: function () {
            if (this.count > 0) {
                this.count -= 1
                // update database
                if (this.count === 0) {
                    const parcoursRef = db.collection('parcours').doc(this.docID)
                    const increment = firebase.firestore.FieldValue.increment(-1)
                    parcoursRef.update({ attendees: increment, isWaiting: false, briefingID: '' })
                    this.stopTimer()
                    this.resetTimer()
                } else {
                    const parcoursRef = db.collection('parcours').doc(this.docID)
                    const increment = firebase.firestore.FieldValue.increment(-1)
                    parcoursRef.update({ attendees: increment })
                }
            }
        },
        selectTrainer: function () {
            const parcoursRef = db.collection('parcours').doc(this.docID)
            parcoursRef.update({ trainer: this.preselectedTrainer })
        },
        startSB: function () {
            if (this.count > 0) {
                this.stopTimer()
                // save SB
                router.push({ name: 'new', params: { parcours: this.parcoursID, attendees: this.count, docID: this.docID, trainer: this.preselectedTrainer, blockDuration: this.blockDuration, briefingID: this.briefingID } })
            }
        },
        generateID: function () {
            var timepart = Date.now() + parseInt(this.timeOffset)
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var randomPart = ''
            var i
            for (i = 0; i < 6; i++) {
                randomPart += characters.charAt(Math.floor(Math.random() * characters.length))
            }
            var uuid = timepart + randomPart

            return uuid
        }
    },
    computed: {
        formattedElapsedTime () {
            const date = new Date(null)
            if (!(isNaN(this.elapsedTime)) && this.elapsedTime > 0) {
                date.setSeconds(this.elapsedTime / 1000)
                const utc = date.toUTCString()
                return utc.substr(utc.indexOf(":") + 1, 5)
            } else {
                return "00:00"
            }
        },
        formattedBlockTime () {
            const date = new Date(null)
            if (!(isNaN(this.remainingBlockTime)) && this.remainingBlockTime > 0) {
                date.setMinutes(this.blockDuration - (this.remainingBlockTime / 1000 / 60))
                return date.getMinutes()
            } else {
                return "0"
            }
        },
        formattedBlockTimePercent () {
            return this.formattedBlockTime / this.blockDuration * 100
        }
    }
}
</script>
